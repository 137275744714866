@import '@hxm/styles/config';

.container {
  display: flex;
  flex-direction: row;

  padding-top: var(--message-padding-top, 0.5em);
  padding-bottom: var(--message-padding-bottom, 0.5em);

  width: 100%;
  min-height: var(--message-min-height, unset);

  &.error {
    justify-content: flex-end;
  }
}

.message {
  @include responsive-font($font-size-xs-min, $font-size-xs);

  line-height: $line-height;

  &.error {
    color: $color-red;
  }

  &.info {
    font-weight: 500;

    color: $color-primary-ui;
  }

  &.error-left {
    color: $color-red;
  }
}
