@import '@hxm/styles/config';

.headerLogin {
  z-index: $zindex-header;

  height: var(--header-height, $header-height);

  @media (min-width: $min-desktop) {
    position: relative;
  }

  &__skip {
    position: absolute;
    top: 0;

    transform: translateY(-200%);

    &:focus {
      transform: none;
    }
  }

  &__logo {
    color: #000;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 $gutter;

    height: var(--header-height, $header-height);
  }
}
