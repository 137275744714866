@import '@hxm/styles/config';

.flex {
  --flex-col-count: 3;
  --__flex-gutter: var(--flex-gutter, #{$gutter});

  &.col4 {
    --flex-col-count: 4;
  }

  @media (min-width: $min-tablet) {
    display: flex;

    margin-right: calc(var(--__flex-gutter) * -1);
  }
}
