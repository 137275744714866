@import '@hxm/styles/config';

.flexCol {
  --__flex-col-gutter: var(--flex-gutter, #{$gutter});
  --__flex-col-count: var(--flex-col-count, 3);

  @media (min-width: $min-tablet) {
    display: flex;
    flex-direction: column;

    margin-right: var(--__flex-col-gutter);

    &:last-child {
      margin-right: 0;
    }

    &.flexCol1 {
      width: calc((1 / var(--__flex-col-count) * 100%) - var(--__flex-col-gutter));
    }

    &.flexCol2 {
      width: calc((2 / var(--__flex-col-count) * 100%) - var(--__flex-col-gutter));
    }

    &.flexCol3 {
      width: calc((3 / var(--__flex-col-count) * 100%) - var(--__flex-col-gutter));
    }

    &.flexCol4 {
      width: calc((4 / var(--__flex-col-count) * 100%) - var(--__flex-col-gutter));
    }

    > * {
      width: 100%;
    }

    &.growChildren {
      > * {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }
}
